$(document).ready(function () {
  let p = $("body").attr("p");
  let currentSelectedPersonId;
  let selectedPersonCartData = {};

  if (p == "events") {
    let posType = $("#location-products").data("pos-type");
    const organizerId = $("body").attr("organizer_id");

    $(document).on("click", "#add-person", function () {
      const tableId = $(this).attr("data-table-id");

      $.ajax({
        type: "POST",
        url: "/ajax/order/new_table_customer",
        data: { table_id: tableId },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#person-row").append(data.html);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".remove-person", function (e) {
      e.stopPropagation();
      const personId = $(this).attr("data-table-customer-id");
      const parentDiv = $(this).closest(".select-person");

      $.ajax({
        type: "POST",
        url: "/ajax/order/remove_table_customer",
        data: {
          organizer_id: organizerId,
          table_customer_id: personId,
          table_id: locationCart.table_number,
          location_id: locationCart.location_id,
          location_type: locationCart.location_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            parentDiv.fadeOut();
            popupAlert();
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
            setTimeout(() => {
              parentDiv.remove();
              let personCount = $(".select-person").length;
              if (personCount === 0) {
                $("#split-payment-cart-modal").hide();
                $("#split-payment-cart-modal").remove();
                if ($(".modal-backdrop").length) {
                  $(".modal-backdrop").remove();
                }
                $(".cart-modal").show();
              }
            }, 500);
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
            currentSelectedPersonId = null;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", "#split-payment", function () {
      currentSelectedPersonId = null;
      const table_id = locationCart.table_number;
      $.ajax({
        type: "POST",
        url: "/ajax/order/split_payment",
        data: {
          table_id,
          location_id: locationCart.location_id,
          cart_products: locationCart.products,
          location_type: locationCart.location_type,
          organizer_id: organizerId,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#cart-modal").fadeOut();
            $(".modal-backdrop").remove();
            if ($("#split-payment-cart-modal").length) {
              $("#split-payment-cart-modal").remove();
            }
            $("#content").append(data.html);
            $("#split-payment-cart-modal").modal("show");

            if (
              data.cart_products !== undefined &&
              data.cart_products !== null &&
              data.cart_products.length
            ) {
              LHSCartProducts = data.cart_products;
            }

            setPerson();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".select-person", function () {
      $(".person-btn").removeClass("selected");
      const personName = $(this).find(".name-container").text();
      const personId = parseInt($(this).data("person-id"));
      $(".select-person").removeClass("selected");
      $(this).addClass("selected");
      currentSelectedPersonId = personId;
      getPersonProducts();
    });

    function getPersonProducts() {
      $.ajax({
        type: "POST",
        url: "/ajax/order/get_table_customer_products",
        data: {
          table_customer_id: currentSelectedPersonId,
          table_id: locationCart.table_number,
          location_id: locationCart.location_id,
          location_type: locationCart.location_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    $(document).on("input", ".person-name-input", function () {
      const personName = $(this).val();
      $("#selected-name").text(personName);
    });

    $(document).on("click", ".divide-product", function () {
      if ($(".select-person").length <= 1) {
        return alert("Må være mer enn 2 personer");
      }
      const productId = $(this).attr("data-product-id");
      const product_table_id = $(this).data("product-table-id");

      $.ajax({
        type: "POST",
        url: "/ajax/order/divide_product",
        data: {
          table_customer_id: currentSelectedPersonId ?? null,
          organizer_id: organizerId,
          product_id: productId,
          location_id: locationCart.location_id,
          table_id: locationCart.table_number,
          location_type: locationCart.location_type,
          product_table_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".remove-divided-product", function () {
      const product_table_id = $(this).data("product-table-id");
      const product_table_split_id = $(this).data("product-table-split-id");

      const splitProductCount = $(this).data("split-product-count");

      if (splitProductCount == 1) {
        return alert(
          "Du har allerede tildelt ett av de oppdelte produktene til en kunde."
        );
      }

      $.ajax({
        type: "POST",
        url: "/ajax/order/remove_divide_product",
        data: {
          table_customer_id: currentSelectedPersonId ?? null,
          organizer_id: organizerId,
          location_id: locationCart.location_id,
          table_id: locationCart.table_number,
          location_type: locationCart.location_type,
          product_table_id,
          product_table_split_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
            $(`#customer-product-count-${currentSelectedPersonId}`).text(
              data.products.length || 0
            );
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".add-divided-product-to-person", function () {
      if (!currentSelectedPersonId) {
        return alert("Vennligst velg en person");
      }

      const product_table_split_id = $(this).data("product-table-split-id");
      const customerProductCount = parseFloat(
        $(`#customer-product-count-${currentSelectedPersonId}`).text()
      );

      $.ajax({
        type: "POST",
        url: "/ajax/order/add_divided_product_to_person",
        data: {
          table_customer_id: currentSelectedPersonId,
          table_id: locationCart.table_number,
          location_id: locationCart.location_id,
          location_type: locationCart.location_type,
          product_table_split_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
            $(`#customer-product-count-${currentSelectedPersonId}`).text(
              data.products.length || 0
            );
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".add-to-customer", function () {
      if (!currentSelectedPersonId) {
        return alert("Vennligst velg en person");
      }

      const addByOne = $(this).attr("data-add-one");

      const productId = $(this).data("product-id");
      const productTableId = $(this).data("product-table-id");
      const customerProductCount = parseFloat(
        $(`#customer-product-count-${currentSelectedPersonId}`).text()
      );

      $.ajax({
        type: "POST",
        url: "/ajax/order/update_product_table",
        data: {
          table_customer_id: currentSelectedPersonId,
          product_id: productId,
          table_id: locationCart.table_number,
          location_id: locationCart.location_id,
          location_type: locationCart.location_type,
          product_table_id: productTableId,
          split_by_one: addByOne ? 1 : 0,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#split-payment-product-container").empty();
            $("#split-payment-product-container").html(data.html);
            softProductTableList();
            softPersonProductTableList();
          }

          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationCart.location_id
          ) {
            selectedPersonCartData = data;
            $(`#customer-product-count-${currentSelectedPersonId}`).text(
              data.products.length || 0
            );
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".remove-splited-product", function () {
      const productId = $(this).data("product-id");
      const product_table_id = $(this).data("product-table-id");
      const product_table_split_id = $(this).data("product-table-split-id");
      const customerProductCount = parseFloat(
        $(`#customer-product-count-${currentSelectedPersonId}`).text()
      );

      if (product_table_id) {
        $.ajax({
          type: "POST",
          url: "/ajax/order/update_product_table",
          data: {
            table_customer_id: currentSelectedPersonId,
            product_id: productId,
            table_id: locationCart.table_number,
            location_id: locationCart.location_id,
            location_type: locationCart.location_type,
            product_table_id,
          },
        })
          .done(function (data) {
            if (data.html !== undefined && data.html !== null) {
              $("#split-payment-product-container").empty();
              $("#split-payment-product-container").html(data.html);
              softProductTableList();
              softPersonProductTableList();
            }

            if (
              data.location_id !== undefined &&
              data.location_id !== null &&
              data.location_id == locationCart.location_id
            ) {
              selectedPersonCartData = data;
              $(`#customer-product-count-${currentSelectedPersonId}`).text(
                data.products.length || 0
              );
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }
          });
      }

      if (product_table_split_id) {
        $.ajax({
          type: "POST",
          url: "/ajax/order/add_divided_product_to_person",
          data: {
            table_customer_id: currentSelectedPersonId,
            table_id: locationCart.table_number,
            location_id: locationCart.location_id,
            location_type: locationCart.location_type,
            product_table_split_id,
          },
        })
          .done(function (data) {
            if (data.html !== undefined && data.html !== null) {
              $("#split-payment-product-container").empty();
              $("#split-payment-product-container").html(data.html);
              softProductTableList();
              softPersonProductTableList();
            }

            if (
              data.location_id !== undefined &&
              data.location_id !== null &&
              data.location_id == locationCart.location_id
            ) {
              selectedPersonCartData = data;

              $(`#customer-product-count-${currentSelectedPersonId}`).text(
                data.products.length || 0
              );
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }
          });
      }
    });

    $(document).on("click", "#add-splited-product-to-cart", function () {
      emptyCartOnModalClose = false;
      $("#split-payment-cart-modal").modal("hide");
      $("#split-payment-cart-modal").remove();
      $(".modal-backdrop").remove();

      locationCart.products = [];

      if (posType === "scroll") {
        setLocationCart({
          ...locationCart,
          ...selectedPersonCartData,
          checkout_type: "normal",
          table_customer_id: currentSelectedPersonId,
        });
      } else if (posType === "classic") {
        setClassicLocationCart({
          ...locationCart,
          ...selectedPersonCartData,
          checkout_type: "normal",
          table_customer_id: currentSelectedPersonId,
        });
      }
    });

    $(document).on(
      "hide.bs.modal",
      "#split-payment-cart-modal",
      function (event) {
        $(".select-person").removeClass("selected");
        if (emptyCartOnModalClose) {
          if ($("#split-payment-cart-modal").length) {
            setTimeout(() => {
              $("#split-payment-cart-modal").remove();
            }, 500);
          }
          emptyAllCart();
        }
      }
    );
    $(document).on(
      "show.bs.modal",
      "#split-payment-cart-modal",
      function (event) {
        $(".select-person").removeClass("selected");
        currentSelectedPersonId = null;
        softProductTableList();
        softPersonProductTableList();
      }
    );

    function softProductTableList() {
      if ($("#split-product-table-body").length) {
        const splitProductTableBody = document.getElementById(
          "split-product-table-body"
        );

        Sortable.create(splitProductTableBody, {
          handle: ".product-table-reorder",
          animation: 150,
          sort: true,
          delay: 0,
          touchStartThreshold: 0,
          disabled: false,
          draggable: ".product-table-group",
          direction: "vertical", // Set to vertical for row sorting
          ghostClass: "sortable-ghost",
          dataIdAttr: "data-product-table-id",
          removeCloneOnHide: true,
          store: {
            set: function (sortable) {
              let order = sortable.toArray();
              ajaxListOrder(order);
            },
          },
        });
      }
    }
    function softPersonProductTableList() {
      if ($("#splited-product-table-body").length) {
        const splitedProductTableBody = document.getElementById(
          "splited-product-table-body"
        );

        Sortable.create(splitedProductTableBody, {
          handle: ".person-product-table-reorder",
          animation: 150,
          sort: true,
          delay: 0,
          touchStartThreshold: 0,
          disabled: false,
          draggable: ".person-product-table-group",
          direction: "vertical", // Set to vertical for row sorting
          ghostClass: "sortable-ghost",
          dataIdAttr: "data-product-table-id",
          removeCloneOnHide: true,
          store: {
            set: function (sortable) {
              let order = sortable.toArray();
              ajaxPersonProductListOrder(order);
            },
          },
        });
      }
    }

    function ajaxListOrder($order) {
      $.ajax({
        type: "POST",
        url: "/ajax/order/list_sort",
        data: {
          serialized_post: JSON.stringify($order),
        },
      })
        .done(function (data) {
          if (data === true) {
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function ajaxPersonProductListOrder($order) {
      $.ajax({
        type: "POST",
        url: "/ajax/order/person_product_list_sort",
        data: {
          serialized_post: JSON.stringify($order),
          table_customer_id: currentSelectedPersonId,
          table_id: locationCart.table_number,
        },
      })
        .done(function (data) {
          if (data) {
            popupAlert();
            selectedPersonCartData = { ...selectedPersonCartData, ...data };
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
